import work4Image1 from '../../../assets/images/work/hs-work4-1.webp';
import work4Image2 from '../../../assets/images/work/hs-work4-2.webp';
import work4Image3 from '../../../assets/images/work/hs-work4-3.webp';
import work4Image4 from '../../../assets/images/work/hs-work4-4.webp';
import work4Image5 from '../../../assets/images/work/hs-work4-5.webp';
import work4Image6 from '../../../assets/images/work/hs-work4-6.webp';
import work4Image7 from '../../../assets/images/work/hs-work4-7.webp';

const Client1Gallery = [
    { src: work4Image1, alt:"Home Page", title:"Home Page", description:"Home Page Layout", width: 1600, height: 4456 },
    { src: work4Image2, alt:"Products Listing Page", title:"Products Listing Page", description:"Products Listing Page Layout", width: 1600, height: 5025 },
    { src: work4Image3, alt:"Product Detail Page", title:"Product Detail Page", description:"Product Detail Page Layout", width: 1600, height: 1923 },
    { src: work4Image4, alt:"Services Page", title:"Services Page", description:"Services Page Layout", width: 1600, height: 2186 },
    { src: work4Image5, alt:"News Listing Page", title:"News Listing Page", description:"News Listing Page Layout", width: 1600, height: 1782 },
    { src: work4Image6, alt:"News Detail Page", title:"News Detail Page", description:"News Detail Page Layout", width: 1600, height: 2253 },
    { src: work4Image7, alt:"Contact Page", title:"Contact Page", description:"Contact Page Layout", width: 1600, height: 1519 },
    
  ];
  export default Client1Gallery;