import Toggle from "react-toggle";
//import "react-toggle/style.css"
import { useColorScheme } from "./useColorScheme";
import moonIcon from "../../assets/images/hs-moon-icon.svg";
import sunIcon from "../../assets/images/hs-sun-icon.svg";

export const DarkLightToggle = () => {
  const { isDark, setIsDark } = useColorScheme();
  return (
    <Toggle
      checked={isDark}
      onChange={({ target }) => setIsDark(target.checked)}
      icons={{  unchecked: <img src={sunIcon} alt="Light Mode" title="Light Mode" width={24} height={24} />, checked: <img src={moonIcon} alt="Dark Mode" title="Dark Mode" width={24} height={24} /> }}
      aria-label="Dark mode toggle"
    />
  );
};