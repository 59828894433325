export const _handleWayPointEnter = (event,hsAnimationClass) => {
    //console.log("Waypoint enter " + JSON.stringify(event, 4));
    const coverElements = document.getElementsByClassName(hsAnimationClass);
    Object.keys(coverElements).forEach(key => {
        //console.log(key); 
        var hsElement = coverElements[key];
        var hsAnimationName = coverElements[key].getAttribute('data-hs-animation');
        var hsAnimationDelay = coverElements[key].getAttribute('data-hs-animation-delay');
        //console.log(hsElement+' '+hsAnimationName+' '+hsAnimationDelay);
        hsElement.classList.add('animated', hsAnimationName);
        hsElement.style.animationDelay = hsAnimationDelay;
      });

 }