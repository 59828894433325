import work7Image1 from '../../../assets/images/work/hs-work7-1.webp';
import work7Image2 from '../../../assets/images/work/hs-work7-2.webp';
import work7Image3 from '../../../assets/images/work/hs-work7-3.webp';
import work7Image4 from '../../../assets/images/work/hs-work7-4.webp';


const Client7Gallery = [
    { src: work7Image1, alt:"Home Page", title:"Home Page", description:"Home Page Layout", width: 1366, height: 2966 },
    { src: work7Image2, alt:"About Us Page", title:"About Us Page", description:"About Us Page Layout", width: 1366, height: 3917 },
    { src: work7Image3, alt:"Benefits Page", title:"Benefits Page", description:"Benefits Page Layout", width: 1366, height: 2324 },
    { src: work7Image4, alt:"Contact Us Page", title:"COntact Us Page", description:"Contact Us Page Layout", width: 1366, height: 1943 },
  ];
  export default Client7Gallery;