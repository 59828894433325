// Footer
import { NavLink } from "react-router-dom";
import BackToTop from "../BackToTop/BackToTop";

const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    return(
            <>
                <footer>
                    <div className="hs-wrapper">
                        <div className="hs-footer-inner">
                        <ul className="hs-terms">
                            <li><NavLink to="/" className="hs-home-icon hs-f-home" title="Home" alt="Home"></NavLink></li>
                            {/* <li><NavLink to="/services" alt="Services" title="Services">Services</NavLink></li> */}
                            <li><NavLink to="/work" alt="Work" title="Work">Work</NavLink></li>
                            <li><NavLink to="/about" alt="About" title="About">About</NavLink></li>
                            <li><NavLink to="/contact" alt="Contact" title="Contact">Contact</NavLink></li>
                            <li><NavLink to="/privacy" alt="Privacy" title="Privacy">Privacy</NavLink></li>
                            <li><NavLink to="/terms" alt="Terms" title="Terms">Terms</NavLink></li>
                            <li>&copy; 2016 - {year} <NavLink to="/" alt="Horizonwise Services" title="Horizonwise Services">Horizonwise Services</NavLink> </li>
                        </ul>
                        <span className="hs-coding-clean-icon animated hs-animate-infinite zoomInRight hs-animate-duration5"></span>
                        </div>
                    </div>
                </footer>
                <BackToTop />
            </>
            
    )
}
export default Footer;