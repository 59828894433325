// Footer
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import logo from "../../assets/images/horizonwise-logo.svg";
import logoWhite from "../../assets/images/horizonwise-logo-white.svg";
import { DarkLightToggle } from "../DarkLightToggle/DarkLightToggle";
import { _handleWayPointEnter } from "../../utils";

const Header = () => {
  return (
    <>
      <Waypoint onEnter={(event) => _handleWayPointEnter(event, "hs-header")} />
      <header>
        <div className="hs-wrapper">
          <div className="hs-header-inner">
            <div
              className="hs-logo hs-animation hs-header"
              data-hs-animation="zoomIn"
              data-hs-animation-delay="0s"
            >
              <Link to="/" title="horizonwise - koding klean">
                <img 
                className="hs-light" 
                src={logo} 
                alt="horizonwise logo"
                width={250} 
                height={50}
                />
                <img
                  className="hs-dark"
                  src={logoWhite}
                  alt="horizonwise logo"
                  width={250}
                  height={50}
                />
              </Link>
            </div>
            <div className="hs-contact-top">
              <Link
                to="mailto:contact@horizonwise.com"
                className="hs-animation hs-header"
                data-hs-animation="zoomIn"
                data-hs-animation-delay="0s"
                target="_blank"
                rel="noopener noreferrer"
                title="Email: contact@horizonwise.com"
              >
                <span className="hs-contact-icon">contact</span>
              </Link>
              <div
                className="hs-mode-toggle hs-animation hs-header"
                data-hs-animation="zoomIn"
                data-hs-animation-delay="0s"
              >
                <DarkLightToggle />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
