import React from 'react'
import { useEffect, useState } from "react"

const BackToTop = () => {
  const [backToTop, setBackToTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTop(true)
      } else {
        setBackToTop(false)
      }
    })
  }, [])
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  return (
    <>
      {backToTop ? <button className="hs-back-to-top" aria-label="Back to top" title="Go to top" onClick={scrollUp}>⌃</button> : null}
    </>
  )
}

export default BackToTop