//About
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Waypoint } from 'react-waypoint';
import { Helmet } from "react-helmet-async";
// core components
import SliderClients from "../components/Sliders/SliderClients"
import Footer from '../components/Footer/Footer'
import { _handleWayPointEnter } from '../utils';
import teamImage from '../assets/images/team/Dishant-Mandal.webp';
import atIcon from '../assets/images/hs-att.svg';
import linkedInIcon from '../assets/images/hs-linkedin.svg';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>

            <Helmet>
                <title>About • Horizonwise Services • Kathmandu, Nepal</title>
                <meta name="description" description="Horizonwise Services provides web design and web development services to various businesses, organizations and individuals." />
                <link rel="cononical" href="https://horizonwise.com/about" />
            </Helmet>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-top')} />
            <section className="hs-common-area">
                <div className="hs-wrapper">
                    <h1 className="hs-caption1 hs-animation hs-top" data-hs-animation="fadeInDown" data-hs-animation-delay="0.3s">
                        About
                    </h1>
                    <div className="hs-blue-separator hs-animation hs-top" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                    <div className="hs-common-content hs-about-content hs-animation hs-top" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                       <p>Horizonwise Services is a web development company founded in 2016. We are based in Kathmandu and work with a global clientele covering a wide range of industries and domains.</p>
                        <p>Horizonwise Services develops professional websites for small and medium-sized businesses using the latest technologies. We create modern and maintainable websites that are user-friendly and responsive. Our team is experienced and committed to delivering top-notch services to our clients.</p>
                        <p>We help our customers effectively present and market themselves online. We help you build your brand, improve your web presence, and cultivate meaningful customer relationships.</p>
                    </div>
                </div>
            </section>
            <Waypoint
        onEnter={(event) => _handleWayPointEnter(event, "hs-client-animation")}
      />
      <section className="hs-clients-area">
        <div className="hs-wrapper">
          <div
            className="hs-caption1 hs-animation hs-client-animation"
            data-hs-animation="fadeInDown"
            data-hs-animation-delay="0.3s"
          >
            Clients
          </div>
          <div
            className="hs-blue-separator hs-animation hs-client-animation"
            data-hs-animation="rotateIn"
            data-hs-animation-delay="0.7s"
          ></div>
          <div 
            className="hs-slider hs-animation hs-client-animation" 
            data-hs-animation="fadeInUp"
            data-hs-animation-delay="1.5s"
          >
            <SliderClients />
          </div>
        </div>
      </section>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-animation')} />
            <section className="hs-common-contact-area">
                <div className="hs-wrapper">
                    <div className="hs-half-block hs-border-right">
                        <div className="hs-half-block-inner">
                            <div className="hs-caption1 hs-animation" data-hs-animation="fadeInUp" data-hs-animation-delay="0.3s">
                                Contact Information
                            </div>
                            <div className="hs-blue-separator hs-animation" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                            <div className="hs-animation" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                                <blockquote >
                                    Happy to discuss your project.<br />Send an email to get started.
                                </blockquote>
                                <ul className="hs-contact-info-list">
                                    <li><span className="hs-c-mail"></span><strong>E-Mail:</strong>
                                        <br /><Link to="mailto:contact@horizonwise.com" target="_blank" rel="noopener noreferrer">contact[at]horizonwise.com</Link></li>
                                    <li><span className="hs-c-tel"></span><strong>Tel:</strong><br /><Link to="tel:+97715242255" target="_blank" rel="noopener noreferrer">+977-1-5242255</Link></li>
                                    <li><span className="hs-c-cell"></span><strong>Cell:</strong><br /><Link to="tel:+9779849623444" target="_blank" rel="noopener noreferrer">+977-9849623444</Link></li>
                                    <li><span className="hs-c-add"></span><strong>Address:</strong><br />Kshitij Marg, Shankhamul,<br />Kathmandu,
                                        <br />44600, Bagmati, Nepal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="hs-half-block">
                        <div className="hs-half-block-inner">
                            <div className="hs-caption1 hs-animation" data-hs-animation="fadeInUp" data-hs-animation-delay="0.3s">
                                Point of contact
                            </div>
                            <div className="hs-blue-separator hs-animation" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                            <div className="hs-animation" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                                <div className="hs-contact-person-image">
                                    <img src={teamImage} alt="Dishant Mandal" width="321" />
                                    <div className="hs-contact-person-image-caption">
                                        <h5>Owner, Full Stack Web Developer</h5>
                                        <h3>Dishant Mandal</h3>
                                        <div className="hs-clear"></div>
                                        <Link to="mailto:contact@horizonwise.com" target="_blank" title="E-Mail" rel="noopener noreferrer"><img src={atIcon} alt="Email" /></Link>
                                        <Link to="https://linkedin.com/in/DishantMandal" target="_blank" title="Linkedin" rel="noopener noreferrer"><img src={linkedInIcon} alt="linkedin" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default About;