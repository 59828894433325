import workImage1 from '../../../assets/images/work/hs-work1-1.webp';
import workImage2 from '../../../assets/images/work/hs-work1-2.webp';
import workImage3 from '../../../assets/images/work/hs-work1-3.webp';
import workImage4 from '../../../assets/images/work/hs-work1-4.webp';
import workImage5 from '../../../assets/images/work/hs-work1-5.webp';
//import workImage6 from '../../../assets/images/work/hs-work1-6.webp';

const Client1Gallery = [
    { src: workImage1, alt:"Home Page", title:"Home Page", description:"Home Page Layout", width: 1600, height: 6100 },
    { src: workImage2, alt:"Category Page", title:"Category Page", description:"Category Page Layout", width: 1600, height: 4529 },
    { src: workImage3, alt:"Products Listing Page", title:"Products Listing Page", description:"Products Listing Page Layout", width: 1600, height: 4639 },
    { src: workImage4, alt:"Product Detail Page", title:"Product Detail Page", description:"Product Detail Page Layout", width: 1600, height: 4539 },
    { src: workImage5, alt:"Projects Listing Page", title:"Projects Listing Page", description:"Projects Listing Page Layout", width: 1600, height: 4167 },
    //{ src: workImage6, width: 1600, height: 4554 },
    
  ];
  export default Client1Gallery;