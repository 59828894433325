//Work
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Waypoint } from 'react-waypoint';
import { Helmet } from "react-helmet-async";
// core components
import Footer from '../components/Footer/Footer'
import { _handleWayPointEnter } from '../utils';

import WorkGallery1 from '../components/WorkGallery/WorkGallery1';
import WorkGallery2 from '../components/WorkGallery/WorkGallery2';
import WorkGallery3 from '../components/WorkGallery/WorkGallery3';
import WorkGallery4 from '../components/WorkGallery/WorkGallery4';
import WorkGallery5 from '../components/WorkGallery/WorkGallery5';
//import WorkGallery6 from '../components/WorkGallery/WorkGallery6';
import WorkGallery7 from '../components/WorkGallery/WorkGallery7';

const Work = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Helmet>
                <title>Work • Horizonwise Services • Kathmandu, Nepal</title>
                <meta name="description" description="List of web design and website development works by Horizonwise Services." />
                <link rel="cononical" href="https://horizonwise.com/work" />
            </Helmet>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-top')} />
            <section className="hs-common-area">
                <div className="hs-wrapper">
                    <h1 className="hs-caption1 hs-animation hs-top" data-hs-animation="fadeInDown" data-hs-animation-delay="0.3s">
                        Work
                    </h1>
                    <div className="hs-blue-separator hs-animation hs-top" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                    <div className="hs-common-content hs-animation hs-top" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                        <p className="hs-thank-you">Providing services to various businesses, organizations and individuals.</p>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="hs-animation hs-top" data-hs-animation="bounceInUp" data-hs-animation-delay="2s">
                        <Link className="hs-btn1 hs-fast-trans" to="/contact" title="Start your project">
                            Start your project
                        </Link>
                    </div>
                </div>
            </section>
            <section className='hs-work-area'>
                <div className="hs-wrapper">
                    <WorkGallery1 />
                </div>
                <div className='hs-gallery-separator'></div>
                <div className="hs-wrapper">
                    <WorkGallery2 />
                </div>
                <div className='hs-gallery-separator'></div>
                <div className="hs-wrapper">
                    <WorkGallery3 />
                </div>
                <div className='hs-gallery-separator'></div>
                <div className="hs-wrapper">
                    <WorkGallery4 />
                </div>
                <div className='hs-gallery-separator'></div>
                <div className="hs-wrapper">
                    <WorkGallery5 />
                </div>
                {/* <div className='hs-gallery-separator'></div>
            <div className="hs-wrapper">
                <WorkGallery6 />
            </div>  */}
                <div className='hs-gallery-separator'></div>
                <div className="hs-wrapper">
                    <WorkGallery7 />
                </div>

            </section>
            <Footer />
        </>
    );
}

export default Work;