// Main
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

//pages
import Home from '../pages/Home';
import Work from '../pages/Work';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import Testimonials from '../pages/Testimonial';
import Layout from '../pages/Layout';
import NoPage from '../pages/NotFound';
//import Services from "../pages/Services";


class Main extends Component {
    render() {
        return (
	        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                {/* <Route exact path="/" element={Home} /> */}
                <Route index Component={Home} />
                {/* <Route path="/services" Component={Services} /> */}
                <Route path="/work" Component={Work} />
                <Route path="/about" Component={About} />
                <Route path="/contact" Component={Contact} />
                <Route path="/privacy" Component={Privacy} />
                <Route path="/terms" Component={Terms} />
                <Route path="/testimonial" Component={Testimonials} />
                <Route path="*" Component={NoPage} />
                </Route>
            </Routes>
	       </BrowserRouter> 
        );
    }
}
export default Main;