import { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { _handleWayPointEnter } from '../../utils';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";



import Client7Gallery from "./Clients/Client7Gallery";


function WorkGallery7() {
  const [index, setIndex] = useState(-1);

  window.addEventListener("load",function() {
    const nodeList = document.querySelectorAll(".hs-work-animation7");
    let delayA = 0.7;
  for (let i = 0; i < nodeList.length; i++) {
    nodeList[i].setAttribute("data-hs-animation-delay", delayA.toFixed(1)+"s");
    delayA = delayA + 0.2;
  }
  });
  
  return (
    <>
      <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-work-animation7')} />
      <PhotoAlbum
        photos={Client7Gallery}
        layout="masonry"
        targetRowHeight={150}
        onClick={({ index }) => setIndex(index)}
        columns={(containerWidth) => {
          if (containerWidth < 400) return 1;
          if (containerWidth < 800) return 2;
          if (containerWidth < 1024) return 3;
          if (containerWidth < 1280) return 4;
          return 5;
        }}
        componentsProps={(containerWidth) => ({
          imageProps: { loading: (containerWidth || 0) > 600 ? "eager" : "lazy", className: "hs-animation hs-work-animation7 hs-work-image", 'data-hs-animation':"zoomIn", "data-hs-animation-delay":"0.7s"},
        })}
      />

      <Lightbox
        slides={Client7Gallery}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
      />
    </>
  );
}

export default WorkGallery7;