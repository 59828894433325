//Terms
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
// core components
import Footer from '../components/Footer/Footer';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Helmet>
                <title>Terms of Service • Horizonwise Services • Kathmandu, Nepal</title>
                <meta name="description" description="This page informs you of Terms of Service of Horizonwise Services." />
                <link rel="cononical" href="https://horizonwise.com/terms" />
            </Helmet>
            <section className="hs-terms-area">
                <div className="hs-wrapper">
                    <h1 className="hs-caption1">Terms of Service ("Terms")</h1>

                    <p>Last updated: December 21, 2016</p>

                    <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the
                        <Link to="https://www.horizonwise.com">https://www.horizonwise.com</Link> website (the "Service") operated
                        by Horizonwise Services Pvt. Ltd.</p>
                    <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with
                        these Terms.
                        These Terms apply to all visitors, users and others who access or use the Service.</p>
                    <p>By accessing or using the Service you agree to be bound by these Terms.
                        If you disagree with any part of the terms then you may not access the Service.</p>

                    <strong>Termination</strong>
                    <p>We may terminate or suspend access to our Service immediately, without prior notice or liability,
                        for any reason whatsoever,
                        including without limitation if you breach the Terms.</p>
                    <p>All provisions of the Terms which by their nature should survive termination shall survive
                        termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity
                        and limitations of liability.</p>

                    <strong>Links To Other Web Sites</strong>
                    <p>Our Service may contain links to third­party web sites or services that are not owned or controlled by Horizonwise Services Pvt. Ltd.</p>
                    <p>Horizonwise Services Pvt. Ltd. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any
                        third party web sites or services. You further acknowledge and agree that  Horizonwise Services Pvt. Ltd. shall not be responsible or
                        liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on
                        any such content, goods or services available on or through any such web sites or services.</p>
                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third­party web sites or services that you visit.</p>

                    <strong>Governing Law</strong>
                    <p>These Terms shall be governed and construed in accordance with the laws of Nepal, without regard to its conflict of law provisions.</p>
                    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these
                        Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms
                        constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us
                        regarding the Service.</p>
                    <strong>Changes</strong>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to
                        provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole
                        discretion.</p>
                    <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.
                        If you do not agree to the new terms, please stop using the Service.</p>

                    <strong> Contact Us</strong>
                    <p>If you have any questions about these Terms, please <Link to="mailto:contact@horizonwise.com" target="_blank" rel="noopener noreferrer">contact us</Link>.</p>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default Terms;