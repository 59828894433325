//Home
import { useEffect, lazy, Suspense } from "react"
//import { preload } from 'react-dom';
import { Link } from "react-router-dom"
import { Waypoint } from "react-waypoint"
import { Helmet } from "react-helmet-async"

// core components
//import HomeSEO from "../components/SEO/HomeSEO"
import SliderClientsSkeleton from "../components/Sliders/SliderClientsSkeleton"
import { _handleWayPointEnter } from "../utils"
import Footer from "../components/Footer/Footer"
import hsSVGIcon from "../assets/images/symbol-defs.svg"
import ContactForm from "../components/Forms/ContactForm"
//import SliderClients from "../components/Sliders/SliderClients"
//import SliderWebsiteTypes from "../components/Sliders/SliderWebsiteTypes"
//import SliderTechnologies from "../components/Sliders/SliderTechnologies"
const SliderClients = lazy(() => import("../components/Sliders/SliderClients"))
const SliderWebsiteTypes = lazy(() => import("../components/Sliders/SliderWebsiteTypes"))
const SliderTechnologies = lazy(() => import("../components/Sliders/SliderTechnologies"))


const Home = () => {
  //const mdash = String.fromCodePoint(0x201);
  //const copyright = String.fromCodePoint(0x00A9);
  // preload("../assets/images/hs-cover-bgnd.webp", {
  //   as: "image",
  //   fetchPriority: "high"
  // });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>Horizonwise Services • Web Design & Web Development Company • Kathmandu, Nepal</title>
        <meta name="description" description="Custom web design and web development services to meet your business needs. Build a new website or revamp your existing site with current trends." />
        <link rel="cononical" href="https://horizonwise.com" />
      </Helmet>
      <section className="hs-cover-area">
        {/* <!-- <div id="hs-cover-canvas"></div>--> */}
        <div className="hs-wrapper">
          <div className="hs-cover-top">
            <Waypoint
              onEnter={(event) =>
                _handleWayPointEnter(event, "hs-cover-animation")
              }
            />
            <h1 className="hs-caption1">
              <span
                className="hs-cover-animation"
                data-hs-animation="slideInLeft"
                data-hs-animation-delay="0.3s"
              >
                Web Design, Web App, &
              </span>
              <span
                className="hs-cover-animation"
                data-hs-animation="slideInLeft"
                data-hs-animation-delay="1.5s"
              >
                Website Development Services
              </span>
            </h1>
            <div
              className="hs-cover-animation hs-blue-separator"
              data-hs-animation="rotateIn"
              data-hs-animation-delay="1.7s"
            ></div>
            <div
              className="hs-cover-animation"
              data-hs-animation="bounceInUp"
              data-hs-animation-delay="2s"
            >
              <Link
                to="/contact"
                className="hs-btn1 hs-fast-trans"
                title="Contact for project consultation"
              >
                Contact
              </Link>
            </div>
          </div>
          <h2
            className="hs-cover-animation hs-caption2"
            data-hs-animation="zoomIn"
            data-hs-animation-delay="2.5s"
          >
            Custom-built Website
            <br />
            optimized for performance
          </h2>
        </div>
      </section>
      <Waypoint onEnter={(event) => _handleWayPointEnter(event, "hs-main")} />
      <section className="hs-main-area">
        <div className="hs-wrapper">
          <div
            className="hs-caption1 hs-animation hs-main"
            data-hs-animation="fadeInDown"
            data-hs-animation-delay="0.3s"
          >
            Services
          </div>
          <div
            className="hs-blue-separator hs-animation hs-main"
            data-hs-animation="rotateIn"
            data-hs-animation-delay="0.7s"
          ></div>
          <Waypoint
            onEnter={(event) =>
              _handleWayPointEnter(event, "hs-step-animation")
            }
          />
          <ul className="hs-steps">
            <li
              className="hs-step-animation"
              data-hs-animation="fadeInRight"
              data-hs-animation-delay="0.5s"
            >
              <span
                className="hs-step-cnt hs-step-animation"
                data-hs-animation="fadeOut"
                data-hs-animation-delay="0.9s"
              >
                01
              </span>
              <span className="hs-step-txt hs-hvr-underline-from-center">
                Discuss
              </span>
              <svg className="hs-step-icon hs-icon-discuss">
                <use xlinkHref={`${hsSVGIcon}#hs-discuss-icon`} />
              </svg>
            </li>
            <li
              className="hs-step-animation"
              data-hs-animation="fadeInRight"
              data-hs-animation-delay="0.9s"
            >
              <span
                className="hs-step-cnt hs-step-animation"
                data-hs-animation="fadeOut"
                data-hs-animation-delay="1.3s"
              >
                02
              </span>
              <span className="hs-step-txt hs-hvr-underline-from-center">
                Discover
              </span>
              <svg className="hs-step-icon hs-icon-discover">
                <use xlinkHref={`${hsSVGIcon}#hs-discover-icon`} />
              </svg>
            </li>
            <li
              className="hs-step-animation"
              data-hs-animation="fadeInRight"
              data-hs-animation-delay="1.3s"
            >
              <span
                className="hs-step-cnt hs-step-animation"
                data-hs-animation="fadeOut"
                data-hs-animation-delay="1.7s"
              >
                03
              </span>
              <span className="hs-step-txt hs-hvr-underline-from-center">
                Design
              </span>
              <svg className="hs-step-icon hs-icon-design">
                <use xlinkHref={`${hsSVGIcon}#hs-design-icon`} />
              </svg>
            </li>
            <li
              className="hs-step-animation"
              data-hs-animation="fadeInRight"
              data-hs-animation-delay="1.7s"
            >
              <span
                className="hs-step-cnt hs-step-animation"
                data-hs-animation="fadeOut"
                data-hs-animation-delay="2.1s"
              >
                04
              </span>
              <span className="hs-step-txt hs-hvr-underline-from-center">
                Develop
              </span>
              <svg className="hs-step-icon hs-icon-develop">
                <use xlinkHref={`${hsSVGIcon}#hs-develop-icon`} />
              </svg>
            </li>
            <li
              className="hs-step-animation"
              data-hs-animation="fadeInRight"
              data-hs-animation-delay="2.1s"
            >
              <span
                className="hs-step-cnt hs-step-animation"
                data-hs-animation="fadeOut"
                data-hs-animation-delay="2.5s"
              >
                05
              </span>
              <span className="hs-step-txt hs-hvr-underline-from-center">
                Deploy
              </span>
              <svg className="hs-step-icon hs-icon-deploy">
                <use xlinkHref={`${hsSVGIcon}#hs-deploy-icon`} />
              </svg>
            </li>
          </ul>
          <div className="clearfix"></div>
          <Waypoint
            onEnter={(event) => _handleWayPointEnter(event, "hs-ks-animation")}
          />
          <div className="hs-keys-services">
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="0.2s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Start from scratch, build a new website.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="0.4s"
              >
                <span className="hs-psd2html-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">
                  PSD to HTML5, CSS3 & JavaScript
                </h2>

              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="0.6s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Revamp and rebuild your existing site with current trends.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="0.8s"
              >
                <span className="hs-uxui-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">UX/UI Design</h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="1s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Go mobile, handheld friendly.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="1.2s"
              >
                <span className="hs-rwd-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">
                  Responsive/Adaptive Web Design
                </h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="1.4s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Build your online presence.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="1.6s"
              >
                <span className="hs-webdev-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">Web Development</h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="1.8s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Spark your business and services.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="2s"
              >
                <span className="hs-payment-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">
                  Payment Gateway Integration
                </h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="2.2s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Be strategic manage efficiently.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="2.4s"
              >
                <span className="hs-api-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">API Integration</h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="2.6s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span>{/*Elevate your identity.*/}<span className="hs-key-txt">Interact with users.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="2.8s"
              >
                <span className="hs-app-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">App Development</h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="3s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Stay in touch with your customers and network.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="3.2s"
              >
                <span className="hs-share-this-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">
                  Social Media Integration
                </h2>
              </div>
            </div>
            <div className="hs-key-service">
              <div
                className="hs-key hs-ks-animation"
                data-hs-animation="fadeInLeft"
                data-hs-animation-delay="3s"
              >
                <span className="hs-3-leaf-clover-black-icon"></span><span className="hs-key-txt">Get acquainted with secure, fast and reliable hosting provider.</span>
              </div>
              <div
                className="hs-service hs-ks-animation"
                data-hs-animation="slideInRight"
                data-hs-animation-delay="3.6s"
              >
                <span className="hs-web-hosting-blue-icon"></span>
                <h2 className="hs-hvr-underline-from-right">
                  Web Hosting Consulting
                </h2>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>

          <div
            className="hs-animation hs-animate-infinite hs-animate-duration5 hs-ks-animation"
            data-hs-animation="shake"
            data-hs-animation-delay="0.7s"
          >
            <Link
              className="hs-btn1 hs-fast-trans"
              to="/work"
              alt="Work"
              title="Work"
              aria-label="See all work samples"
            >
              See work
            </Link>
            {/* <Link to="mailto:contact@horizonwise.com" target="_blank" rel="noopener noreferrer"  title="Email for more work samples: contact@horizonwise.com" className="hs-btn1 hs-fast-trans">Request portfolio</Link> */}
          </div>
        </div>
      </section>
      <Waypoint
        onEnter={(event) => _handleWayPointEnter(event, "hs-wtt-animation")}
      />
      <section className="hs-web-technologies-types">
        <div className="hs-wrapper">
          <div className="hs-half-block">
            <div className="hs-half-block-inner">
              <div
                className="hs-caption1 hs-animation hs-wtt-animation"
                data-hs-animation="fadeInDown"
                data-hs-animation-delay="0.3s"
              >
                Design & Development using
              </div>
              <div
                className="hs-blue-separator hs-animation hs-wtt-animation"
                data-hs-animation="rotateIn"
                data-hs-animation-delay="0.7s"
              ></div>
              <div 
              className="hs-slider hs-animation hs-wtt-animation" 
              data-hs-animation="fadeInUp"
              data-hs-animation-delay="1.5s"
              >
                <Suspense fallback={<SliderClientsSkeleton />}>
                  <SliderTechnologies />
                </Suspense>
              </div>
            </div>
          </div>
          <div className="hs-half-block hs-border-left">
            <div className="hs-half-block-inner">
              <div
                className="hs-caption1 hs-animation hs-wtt-animation"
                data-hs-animation="fadeInDown"
                data-hs-animation-delay="0.3s"
              >
                Types of Websites
              </div>
              <div
                className="hs-blue-separator hs-animation hs-wtt-animation"
                data-hs-animation="rotateIn"
                data-hs-animation-delay="0.7s"
              ></div>
              <div 
              className="hs-slider hs-animation hs-wtt-animation" 
              data-hs-animation="fadeInUp"
              data-hs-animation-delay="1.5s"
              >
                <Suspense fallback={<SliderClientsSkeleton />}>
                  <SliderWebsiteTypes />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Waypoint
        onEnter={(event) => _handleWayPointEnter(event, "hs-client-animation")}
      />
      <section className="hs-clients-area">
        <div className="hs-wrapper">
          <div
            className="hs-caption1 hs-animation hs-client-animation"
            data-hs-animation="fadeInDown"
            data-hs-animation-delay="0.3s"
          >
            Clients
          </div>
          <div
            className="hs-blue-separator hs-animation hs-client-animation"
            data-hs-animation="rotateIn"
            data-hs-animation-delay="0.7s"
          ></div>
          <div 
            className="hs-slider hs-animation hs-client-animation" 
            data-hs-animation="fadeInUp"
            data-hs-animation-delay="1.5s"
          >
            <Suspense fallback={<SliderClientsSkeleton />}>
              <SliderClients />
            </Suspense>
            
          </div>
        </div>
      </section>
      <Waypoint
        onEnter={(event) =>
          _handleWayPointEnter(event, "hs-testimonial-animation")
        }
      />
      <section className="hs-testimonials-area">
        <div className="hs-wrapper">
          <div
            className="hs-caption1 hs-animation hs-testimonial-animation"
            data-hs-animation="fadeInUp"
            data-hs-animation-delay="0.3s"
          >
            Testimonial
          </div>
          <div
            className="hs-blue-separator hs-animation hs-testimonial-animation"
            data-hs-animation="rotateIn"
            data-hs-animation-delay="0.7s"
          ></div>
          <div
            className="hs-animation hs-testimonial-animation"
            data-hs-animation="zoomIn"
            data-hs-animation-delay="1s"
          >
            <blockquote>
              Has been wonderful to work with. Is responsive, get things done in
              a timely manner and very supportive . . .
            </blockquote>
            <div className="clearfix"></div>
            <cite>&mdash; Bob Montgomery, Waterloo Manufacturing</cite>
            <div className="clearfix"></div>
            <Link to="/testimonial" className="hs-read-more" aria-label="Read more about the testimonial">
              Read more
            </Link>
          </div>
        </div>
      </section>

      <Waypoint
        onEnter={(event) => _handleWayPointEnter(event, "hs-note-animation")}
      />
      <section
        id="contactus"
        className="hs-form-area"
        data-ng-controller="ContactController"
      >
        <div className="hs-wrapper">
          <div
            className="hs-caption1 hs-animation hs-note-animation"
            data-hs-animation="fadeInDown"
            data-hs-animation-delay="0.3s"
          >
            Get a quote
          </div>
          <div
            className="hs-blue-separator hs-animation hs-note-animation"
            data-hs-animation="rotateIn"
            data-hs-animation-delay="0.7s"
          ></div>
          <p
            className="hs-animation hs-note-animation"
            data-hs-animation="fadeInUp"
            data-hs-animation-delay="0.5s"
          >
            Happy to answer your queries.
            <br />
            Please fill in the form. Thank you.
          </p>

          <ContactForm 
          className="hs-animation hs-note-animation" 
          data-hs-animation="flipInX"
          data-hs-animation-delay="1s"
          />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home
