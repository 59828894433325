import { useState } from "react";
import { useForm } from "react-hook-form";
import { Waypoint } from 'react-waypoint';
import axios from "axios";

import {_handleWayPointEnter} from '../../utils';

//let renderCount = 0;

function sleep(ms) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

const ContactForm = ({btnClass}) =>{
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [mailSent, setmailSent] = useState(false);
    const errorMessage = 'Please fill out all the fields and submit the form again.';
    const successMessage = 'Form submitted successfully';

    const onSubmit = async (data, e) => {
       try{
            await sleep(1000);
            console.log(data, e);
            axios.post('https://horizonwise.com/mail/sendmail.php', data)
            .then(function (response) {
                if(response.success){
                    setmailSent(true);
                    console.log('success',response.message);
                }else{
                    setmailSent(false);
                    console.log('error',response.message);
                }
            })
            .catch(function (response) {
                setmailSent(false);
                console.log('error',response.message);
            });
       } catch(e){
         setError("service", { 
            type: 'serviceSideError', 
            message: 'something is wrong' 
        });
       }
        
    }
    const onError = (errors, e) => {
        console.log(errors, e);
        console.log('wrong');
    }
  
    //renderCount++;
    return(
        <>
        <Waypoint onEnter={(event) => _handleWayPointEnter(event,'hs-form-animation')} />
        {/* Render Count -- {renderCount} */}
        <form className="hs-form" name="contactForm" onSubmit={ handleSubmit(onSubmit,onError)}>
            <div className="hs-animation hs-form-animation" data-hs-animation="flipInX" data-hs-animation-delay={btnClass?"2s":".3s"}>
            
            {errors.firstname || errors.lastname || errors.email || errors.message ? 
            <div className='hs-error animated fadeIn'>{ errorMessage }</div> 
            : null }
            {mailSent?
            <div className='hs-success animated fadeIn'>{ successMessage }</div> 
            :null}

            <div className="hs-field hs-field-half">
                <select 
                aria-label="Service Type"
                {...register('serviceType',{required: true})}
                className={errors.serviceType?"hs-service-type hs-select has-error":"hs-service-type hs-select"}
                >
                    <option value="">Select Service Type</option>
                    <option value="branding">Branding/Graphic Design</option>
                    <option value="web design">Web Design</option>
                    <option value="website development">Website Development</option>
                    <option value="web app">Web APP Development</option>
                    <option value="seo">Search Engine Optimization</option>
                    <option value="page speed optimization">Page Speed Optimization</option>
                    <option value="website maintenance">Website Maintenance</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div className="hs-field hs-field-half hs-field-half-right">
                <select 
                aria-label="Website Type"
                {...register('websiteType',{required: true})}
                className={errors.websiteType?"hs-website-type hs-select has-error":"hs-website-type hs-select"}
                >
                    <option value="">Select Website Type</option>
                    <option value="one page website">One Page/Single Page/Landing Page</option>
                    <option value="basic website">Basic</option>
                    <option value="business website">Business</option>
                    <option value="ecommerce website">E-commerce/Online Store/Online Shop</option>
                    <option value="b2b website">B2B</option>
                    <option value="automotive">Automotive/Car or Bike Dealership/Vehicle Manangement System</option>
                    <option value="education">Education</option>
                    <option value="forum">Online Forum</option>
                    <option value="membership">Membership/Association</option>
                    <option value="manufacturing">Manufacturing/Industry</option>
                    <option value="construction">Construction/Architecture</option>
                    <option value="accounting">Accounting</option>
                    <option value="medical">Medical & Healthcare</option>
                    <option value="travel tourism">Travel & Tourism</option>
                    <option value="ngo">Non-profit/NGO</option>
                    <option value="fashion photography">Fashion/Photography</option>
                    <option value="personal">Personal</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div className="hs-field hs-field-half">
                <input 
                {...register('firstname',{required: true, minLength: 3})} 
                className={errors.firstname?"hs-firstname has-error":"hs-firstname"} placeholder="Firstname" 
                />
                {errors.firstname?.type === "required" && (
                    <span>Firstname is required</span>
                )}
                {errors.firstname?.type === "minLength" && (
                    <span>The firstname should have at least 3 characters</span>
                )}
            </div>

            <div className="hs-field hs-field-half hs-field-half-right">
                <input 
                {...register('lastname',{required: true, minLength: 3})} 
                className={errors.firstname?"hs-lastname has-error":"hs-lastname"} placeholder="Lastname" 
                />
                {errors.lastname?.type === "required" && (
                    <span>Lastname is required</span>
                )}

                {errors.lastname?.type === "minLength" && (
                    <span>The lastname should have at least 3 characters</span>
                )}
            </div>
            <div className="hs-field">
                <input 
                {...register("email", {
                    required: "Email is required",
                    validate: {
                    maxLength: (v) =>
                        v.length <= 50 || "The email should have at most 50 characters",
                    matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email address must be a valid address",
                    },
                })}
                className={errors.email?"hs-email has-error":"hs-email"}
                placeholder="Email address"
                />
                {errors.email?.message && (
                    <span>{errors.email.message}</span>
                )}
            </div>
            <div className="hs-field">
                <textarea 
                    {...register('message',{required: true, minLength: 10})}
                    rows="5" 
                    className={errors.email?"hs-message has-error":"hs-message"} 
                    placeholder="Your project details..."
                >
                </textarea>
                {errors.message?.type === "required" && (
                    <span>Project detail is required</span>
                )}

                {errors.message?.type === "minLength" && (
                    <span>The project detail should have at least 10 characters</span>
                )}
            </div>
            <span className="hs-info"><sup>*</sup>All fields are mandatory.</span><br />
            </div>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event,'hs-send-note')} />
            <button 
                className={btnClass?"hs-btn1 hs-btn1-sm hs-fast-trans hs-animation hs-send-note":"hs-btn1 hs-fast-trans hs-animation hs-send-note" }
                data-hs-animation="bounceInUp" 
                data-hs-animation-delay="0.5s" 
                type="submit" 
                title="Submit form"
            >
            Send
            </button>
        </form>
        </>

    );
}
export default ContactForm;