//Terms
import { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { Helmet } from "react-helmet-async";
// core components
import Footer from '../components/Footer/Footer';
import { _handleWayPointEnter } from '../utils';
import clientImage from '../assets/images/clients/Bob_Montgomery.webp';

const Testimonials = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Helmet>
                <title>Testimonials • Horizonwise Services • Kathmandu, Nepal</title>
                <meta name="description" description="What people say about Horizonwise Services." />
                <link rel="cononical" href="https://horizonwise.com/testimonial" />
            </Helmet>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-animation')} />
            <section className="hs-testimonial-area">
                <div className="hs-wrapper">
                    <h1 className="hs-caption1 hs-animation" data-hs-animation="fadeInDown" data-hs-animation-delay="0.3s">Testimonial</h1>
                    <div className="hs-blue-separator hs-animation" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                    <div className="hs-testimonial-detail hs-animation" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                        <div className="hs-testimonial-left">
                            <div className="hs-testimonial-text">
                                <p>We at Waterloo Manufacturing were struggling with how to manage our website
                                    content. Trying to keep things current or add new items was time consuming and
                                    difficult, if not impossible. Horizonwise was recommended to us along with migrating from our existing system to
                                    Wordpress as our website content management system.
                                    They exceeded our expectations on all counts. Work was completed ahead of
                                    schedule, within budget, was very well done, and provided an easy to use
                                    interface for us to manage our site and content. They worked directly with our website hosting service to move
                                    the new site onto that system. They also spent time with us teaching us how things worked so we
                                    could maintain and grow our site as needed. Even though we are located on opposite sides of the planet, there were no
                                    issues communicating back and forth, including using live web conferencing
                                    during the project. I highly recommend their services. We will continue to use their talents as needed.
                                </p>
                            </div>
                        </div>
                        <div className="hs-testimonial-right">
                            <div className="hs-testimonial-right-inner">
                                <img src={clientImage} alt="Bob Montgomery" />

                                <div className="hs-client-name">Bob Montgomery</div>
                                <div className="hs-client-name">Waterloo Manufacturing</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
}

export default Testimonials;