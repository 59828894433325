//Page Not Found
import { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { Helmet } from "react-helmet-async";
// core components
import Footer from '../components/Footer/Footer';
import { _handleWayPointEnter } from '../utils';

const NoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <Helmet>
        <title>Not Found • Horizonwise Services • Kathmandu, Nepal</title>
        <meta name="description" description="Page not found." />
      </Helmet>
      <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-pnf')} />
      <section className="hs-common-area hs-404">
        <div className="hs-wrapper">
          <h1 className="hs-caption1 hs-animation hs-pnf" data-hs-animation="fadeInDown" data-hs-animation-delay="0.3s">404 - Page not found</h1>
          <div className="hs-blue-separator hs-animation hs-pnf" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
          <div className="hs-404-content hs-common-content hs-animation hs-pnf" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
            <blockquote>Sorry, the page you're looking for could not be found.</blockquote>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );


};

export default NoPage;
