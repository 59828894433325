//Contact
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// core components
import Footer from '../components/Footer/Footer';
import { Waypoint } from 'react-waypoint';
import { _handleWayPointEnter } from '../utils';
import ContactForm from '../components/Forms/ContactForm';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Helmet>
                <title>Contact • Horizonwise Services • Kathmandu, Nepal</title>
                <meta name="description" description="Contact for web design and website development services." />
                <link rel="cononical" href="https://horizonwise.com/contact" />
            </Helmet>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-top')} />
            <section className="hs-common-area">
                <div className="hs-wrapper">

                    <h1 className="hs-caption1 hs-animation hs-top" data-hs-animation="fadeInDown" data-hs-animation-delay="0.3s">
                        Contact
                    </h1>
                    <div className="hs-blue-separator hs-animation hs-top" data-hs-animation="rotateIn" data-hs-animation-delay="0.7s"></div>
                    <div className="hs-common-content hs-animation hs-top" data-hs-animation="zoomIn" data-hs-animation-delay="1s">
                        <p>Happy to discuss your project.<br />Contact to get started.</p>
                    </div>
                </div>
            </section>
            <Waypoint onEnter={(event) => _handleWayPointEnter(event, 'hs-animation')} />
            <section className="hs-common-contact-area">
                <div className="hs-wrapper">
                    <div className="hs-half-block hs-border-right">
                        <div className="hs-half-block-inner">
                            <div className="hs-caption1 hs-animation" data-hs-animation="fadeInUp" data-hs-animation-delay="1s">
                                Contact Information
                            </div>

                            <div className="hs-blue-separator hs-animation" data-hs-animation="rotateIn" data-hs-animation-delay="1.4s"></div>
                            <div className="hs-animation" data-hs-animation="zoomIn" data-hs-animation-delay="1.7s">

                                <ul className="hs-contact-info-list">
                                    <li><span className="hs-c-mail"></span><strong>E-Mail:</strong>
                                        <br /><Link to="mailto:contact@horizonwise.com" target="_blank" rel="noopener noreferrer">contact[at]horizonwise.com</Link></li>
                                    <li><span className="hs-c-tel"></span><strong>Tel:</strong><br /><Link to="tel:+97715242255" target="_blank" rel="noopener noreferrer">+977-1-5242255</Link></li>
                                    <li><span className="hs-c-cell"></span><strong>Cell:</strong><br /><Link to="tel:+9779849623444" target="_blank" rel="noopener noreferrer">+977-9849623444</Link></li>
                                    <li><span className="hs-c-add"></span><strong>Address:</strong><br />Kshitij Marg, Shankhamul,<br />Kathmandu,
                                        <br />44600, Bagmati, Nepal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="hs-half-block">
                        <div className="hs-half-block-inner">
                            <div className="hs-caption1 hs-animation" data-hs-animation="fadeInUp" data-hs-animation-delay="1s">
                                Request a quote
                            </div>
                            <div className="hs-blue-separator hs-animation" data-hs-animation="rotateIn" data-hs-animation-delay="1.4s"></div>
                            <ContactForm btnClass="hs-btn1-sm" />
                            {/* <div id="contactus" className="hs-contact-page-form" data-ng-controller="ContactController">
                <form className="hs-form"  name="contactForm" validation method="post" action="" >
                <div className="hs-animation" data-hs-animation="zoomIn" data-hs-animation-delay="1.7s">
                <div data-ng-hide="formError()" data-ng-className="result">
                     {{resultMessage}} 
                </div>
                <input type="text" name="firstname" id="firstname" className="hs-firstname" placeholder="John/Jane" data-ng-model="contactData.firstname" data-ng-minlength="3" ng-className="{ 'has-error': contactForm.firstname.$invalid && submitted }" required />
                <input type="text" name="lastname" id="lastname" className="hs-lastname" placeholder="Doe" data-ng-model="contactData.lastname" data-ng-minlength="3" ng-className="{ 'has-error': contactForm.lastname.$invalid && submitted }" required /><br />
                <input type="email" name="email" id="email" placeholder="Email Address" data-ng-model="contactData.email" data-ng-minlength="3"  ng-className="{ 'has-error': contactForm.email.$invalid && submitted }" required /><br />
                <textarea id="inputMessage" name="message" rows="5" data-ng-model="contactData.message" placeholder="Message..." ng-className="{ 'has-error': contactForm.message.$invalid && submitted }" required="required"></textarea><br />
                <span className="hs-info">*All fields are mandatory.</span><br />
                </div>
                <Waypoint onEnter={(event) => _handleWayPointEnter(event,'hs-send-note')} />
                <button className="hs-btn1 hs-btn1-sm hs-fast-trans hs-animation hs-send-note" data-hs-animation="fadeInUp" data-hs-animation-delay="2.4s" type="button" form="contactForm" value="Send" data-ng-disabled="submitButtonDisabled" data-ng-click="submit(contactForm)" title="Submit form">Send</button>
                </form>
            </div> */}
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>

    );
}

export default Contact;